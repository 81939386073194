<template>
  <div class="">
    <Loading v-if="pgLoading"> </Loading>

    <div v-if="!pgLoading" class="row">
      <div class="col-md-3" v-for="(item, idx) in items" :key="idx">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <div style="text-align: left">
                  <button
                    type="button"
                    @click="editItem(item.encrypt_id)"
                    class="btn btn-light btn-rounded btn-sm"
                    style="
                      width: 32px;
                      height: 32px;
                      padding: 0;
                      margin-left: 5px;
                    "
                  >
                    <i class="mdi mdi-pencil font-size-16"></i>
                  </button>
                  <button
                    type="button"
                    @click="
                      item.loading = true;
                      deleteItem(item.encrypt_id);
                    "
                    class="btn btn-light btn-rounded btn-sm"
                    style="width: 32px; height: 32px; padding: 0"
                  >
                    <i class="mdi mdi-delete font-size-16"></i>
                  </button>
                </div>
                <h2
                  class="text-muted fw-medium mt-1 text-center"
                  :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                  v-html="$i18n.locale == 'ar' ? item.ar.title : item.en.title"
                />
                <p class="text-center">
                  <router-link
                    :to="
                      '/front-settings/show?parent_id=' +
                      item.encrypt_id +
                      '&slug=' +
                      item.title
                    "
                    class="btn btn-dark btn-rounded mt-2"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    {{ $t("app.edit") }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import Loading from "@/components/Loading.vue";

export default {
  namme: "Cards",
  props: ["refs"],
  components: {
    Loading,
  },
  data() {
    return {
      auth: {
        role: "",
        id_token: "",
      },
      permissions: {
        add: false,
      },
      pgLoading: true,
      items: [],
    };
  },
  created() {
    // accessToken
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    // fetchData
    this.fetchData();
  },
  methods: {
    fetchData(loading = false) {
      if (loading) {
        this.pgLoading = true;
      }
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const options = {
        url: this.$props.refs,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.items = res.data.data.rows;
          this.permissions.add = res.data.data.permissions.add;
          this.$emit("hasAddNewPermission", this.permissions.add);
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    editItem(id) {
      this.$router.push({
        name: "edit-" + this.$props.refs,
        params: { id: id },
        query: { parent_id: this.parent_id, slug: this.slug },
      });
    },

    deleteItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.id_token,
        };
        const options = {
          url: this.$props.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
