<template>
  <div class="page-content">
    <div class="container-fluid">
      <Cards :refs="refs"> </Cards>
    </div>
  </div>
</template>

<script>
import Cards from "@/components/Cards.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default {
  name: "List",
  components: {
    Cards,
  },
  created() {
    setCurrentPageTitle(this.$t("nav.front-settings"), "bx bx-wrench");
  },
  data() {
    return {
      //
      addNew: false,
      cards: [],
      refs: "front-settings",
    };
  },
};
</script>
